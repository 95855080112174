<template>
    <div class="checkTask">
      <div class="tableBox">
          <!-- blue_title -->
          <span class="blueTitle" style="margin-left:12px;">Cobb角计算</span>
          <div style="display:flex;margin-top: 20px;">
            <div class="result">
                    <span class="seachText" style="margin-left:14px">评估结果：</span>
                    <el-select v-model="submitOption" @change="selectResult" style="width: 152px !important">
                        <el-option
                            v-for="item in resultOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
            </div>
            <div class="result">
                <span class="seachText" style="margin-left:14px">检测日期：</span>
                <el-date-picker style="width: 2.20rem;" v-model="seachDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" @change="setDate"></el-date-picker>
            </div>
          </div>
          <div class="tableTop">
             <div class="table_l">
                <div v-if="!isSelect" class="new-down-active" @click="download">批量下载</div>
                <div v-if="!isSelect" class="new-down-active" @click="downloadAllData">下载原始文件</div>
                <div v-if="isSelect" class="new-down-noactive">批量下载</div>
                <div v-if="isSelect" class="new-down-noactive">下载原始文件</div>
                <div v-if="!isSelect" class="new-down-active" @click="deleteIOriginalImg">删除原始图片</div>
                <div v-if="isSelect" class="new-down-noactive">删除原始图片</div>
                 <div :class="{'bolboxl':isActive==1,'noacitve':isActive!=1}" style="width:46px !important" @click="getAll">全部</div>
                 <div :class="{'bolboxl':isActive==2,'noacitve':isActive!=2}" @click="noDo">待处理</div>
                 <div :class="{'bolboxl':isActive==3,'noacitve':isActive!=3}" @click="haveDo">已处理</div>
             </div>
            <div class="table_r">
                
                <div class="search">
                    <el-input
                        placeholder="请输入内容"
                        prefix-icon="el-icon-search"
                        @click.native="selectAllC"
                        class="elSerch"
                        v-model="searchOption">
                        <template slot="append" style="background-color: #00CCCC;">搜索</template>
                    </el-input>
                </div>
            </div>
          </div>
           <CobbTable ref="uetable"/>
      </div>
    </div>
</template>
<script>
//downloadFileAll
import {downloadFileAll,downloadBigFile,deleteOriImg} from '@/api/userManage.js'
import CobbTable from '@/components/table/cobbTable.vue'
export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    components:{
        CobbTable,
    },
    data(){
        return{
            isSelect:false,
            tableSelect:null,//子表勾选状态
           //点击样式控制
           isActive:1,
           //评估结果选择
           resultOption:[ {
                            label:'全部',
                            value: null
                        }, {
                            label:'概率高',
                            value: '高',
                        },
                        {
                            label:'概率低',
                            value:'低',
                        },
                        {
                            label:'不规范',
                            value:'不规范',
                        }
                        ],
        isActiveStatus:null,//处理状态
        searchOption:null,//seach--info
        evaValue:null,//评估结果
        submitOption:"全部",
        seachDate:[],//检测日期
        }
    },
  
    methods:{
        //全部
        getAll(){
            this.isActive=1;
            this.isActiveStatus= null;
            this.$refs.uetable.getData(1,10,null,null,null,this.seachDate[0],this.seachDate[1]);
        },
        noDo(){
            this.isActive=2;
            this.isActiveStatus= "处理中";
            this.$refs.uetable.getData(1,10,this.isActiveStatus,null,null,this.seachDate[0],this.seachDate[1]);
        },
        haveDo(){
            this.isActive=3;
            this.isActiveStatus= "已处理";
            this.$refs.uetable.getData(1,10,this.isActiveStatus,null,null,this.seachDate[0],this.seachDate[1]);
        },
        
        //评估结果选择
        selectResult(e){
            //console.log(e);
            this.evaValue= e;
            this.$refs.uetable.getData(1,10,null,this.evaValue,null,this.seachDate[0],this.seachDate[1]);
        },
       
         //模糊查询-点击
         selectAllC(){
            this.$refs.uetable.getData(1,10,null,null,this.searchOption,this.seachDate[0],this.seachDate[1]);
        },
        //批量下载
        download(){
            const loading = this.$loading({
            lock: true,
            text: '下载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });
            var tempd = [];
            this.tableSelect.forEach((item,index)=>{
                var id = item.id;
                tempd.push(id);
            });

            downloadFileAll({
                ids:tempd
            }).then(res => {
                    if (!res || !res.data) {
                        this.$message.error('暂无');
                        loading.close();
                      }
                    if (res.data.type === 'application/json') {
                        //非进制流文件 在这里
                        const reader = new FileReader()
                        reader.readAsText(res.data)
                        reader.onload = e => {
                        const resData = JSON.parse(reader.result)
                        if(resData.status!=200){
                            loading.close();
                            this.$message.error(resData.message)
                        }
                    }
                    }
                    else{
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        loading.close();
                        this.$refs.uetable.getData(this.$refs.uetable.currentPage,this.$refs.uetable.onlyPage,this.isActiveStatus,this.evaValue,this.searchOption,this.seachDate[0],this.seachDate[1]);
                        
                    }    
            }).catch((err) => {
                console.log("err",err);
                loading.close();
                    
            });  
        },
        //下载原始文件n
        downloadAllData(){
            const loading = this.$loading({
            lock: true,
            text: '下载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });
            var tempd = [];
            this.tableSelect.forEach((item,index)=>{
                var id = item.id;
                tempd.push(id);
            });
            downloadBigFile({
                ids:tempd
            }).then(res => {
                    if (!res || !res.data) {
                        this.$message.error('暂无');
                        loading.close();
                      }
                    if (res.data.type === 'application/json') {
                        //非进制流文件 在这里
                        const reader = new FileReader()
                        reader.readAsText(res.data)
                        reader.onload = e => {
                        const resData = JSON.parse(reader.result)
                        if(resData.status!=200){
                            loading.close();
                            this.$message.error(resData.message)
                         }
                        }
                    }
                    else{
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        loading.close();
                        this.$refs.uetable.getData(this.$refs.uetable.currentPage,this.$refs.uetable.onlyPage,this.isActiveStatus,this.evaValue,this.searchOption,this.seachDate[0],this.seachDate[1]);
                    }    
            }).catch((err) => {
                console.log("err",err);
                loading.close();
                    
            });  
        },
        //删除原始图片  deleteOriImg
        deleteIOriginalImg(){
            const loading = this.$loading({
            lock: true,
            text: '操作中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });
            var tempd = [];
            this.tableSelect.forEach((item,index)=>{
                var id = item.id;
                tempd.push(id);
            });
            deleteOriImg(
                {ids:tempd}
            ).then(res => {
                    if (res.data.status===200) {
                        loading.close();
                        this.$message({
                        message: '操作成功！',
                        type: 'success'
                        });
                        this.$refs.uetable.getData(this.$refs.uetable.currentPage,this.$refs.uetable.onlyPage,this.isActiveStatus,this.evaValue,this.searchOption,this.seachDate[0],this.seachDate[1]);
                      }
                    else{
                        this.$message.error(res.data.message);
                        loading.close();
                    }    
            }).catch((err) => {
                console.log("err",err);
                loading.close();
                    
            });  
        },
        //选择检测日期
        setDate(e){
            this.$refs.uetable.getData(1,10,this.isActiveStatus,this.evaValue,this.searchOption,e[0],e[1]);
        }

    }
}
</script>
<style scoped lang="stylus">
/deep/.el-input-group__append{
    cursor: pointer !important;
}
//下拉框icon样式
/deep/.el-input__icon {
    line-height: 0 !important;
}
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
    border-radius: 4px 0 0 4px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }



//component style
.new-down-noactive
    width: 116px 
    height 32px 
    margin-right: 24px
    background-color:#f4f4f4
    cursor disable
    color: #aeaeae
    line-height: 32px 
    text-align: center
    font-size: 14px
.new-down-active
    width: 116px 
    height 32px 
    margin-right: 24px
    background-color:#00CCCC
    cursor pointer
    color: #fff
    line-height: 32px 
    text-align: center
    font-size: 14px


.blueTitle
  font-size: 16px 
  color: #353535
  line-height: 28px 
  position: relative
  &::before
    width: 3px
    height: 18px
    content: ""
    position: absolute
    border-radius: .03rem
    left: -10px
    bottom:1px
    background-color: #00CCCC
.noacitve 
    width: 68px  
    font-size:16px
    color: #353535
    margin-left: 20px
    cursor pointer
    text-align: center
    opacity .4
.seachText
    font-size: 14px
    color: #AEAEAE
//normal style
.checkTask
    overflow hidden
    .tableBox
        overflow hidden
        margin-top: 16px
        padding: 20px
        background-color: #FFFFFF
        .tableTop
            display: flex
            margin-top: 10px
            margin-bottom: 30px
            justify-content: space-between
            .table_l
                display: flex
                .bolboxl
                    width: 68px  
                    font-size:16px
                    color: #353535
                    margin-left: 20px
                    cursor pointer
                    text-align: center
                    position: relative 
                    &::after 
                        content: '' 
                        position: absolute 
                        width: 100% !important
                        left 0 !important
                        bottom: 6px 
                        height: 4px 
                        border-radius: 5px
                        background-color:#00CCCC!important
            .table_r
                display: flex
                
                .search
                    height: 32px
                    margin-left: 16px
                .delete
                    width: 64px
                    height: 32px
                    background-color: #FC5555
                    text-align: center
                    line-height:32px
                    color: #fff
                    font-size: 14px
                    margin-left: 16px
                    cursor pointer
                    border-radius: 4px

            
</style>